.a{
    height: 100vh;
    display: flex;
    align-items: center;
}

.a-left{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a-right{
    flex:1;
    padding-right: 1%;
}

.a-img.bg{
    width: 30vw;
    height: 38vw;
    position: absolute;
    margin-top: -8vh;
    margin-left: -5vw;
    background-color: #555;
    border-width: 30px;
    border-radius: 30px;
    z-index: -1;
}

.a-img{
    width: 30vw;
    object-fit: cover;
    border-width: 30px;
    border-radius: 30px;
}

.a-title{
    font-weight: 400;
    font-size: 3vw;
}
.a-sub{
    margin: 20px 0px;
    font-size: 1.5vw;
}

.a-desc{
    font-weight: 300;
    font-size: 1.1vw;
}

.a-cat{
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.a-cat-text{
    font-size: 1.1vw;
    margin-right: 10vw;
}

.a-cat-title{
    font-weight: bold;
    color: #555;
    font-size: 1.5vw;
}

.a-cat-img{
    width: 8vw;
    height: 8vw;
    object-fit: cover;
    border-radius: 20px;
}

@media screen and (max-width: 550px) {
    .a{
        flex-direction: column;
        margin-top: 10vw;
    }

    .a-img.bg{
        display: none;
    }

    .a-left{
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .a-img{
        width: 70%;
        object-fit: cover;
    }

    .a-card{
        height: 30vh;
    }

    .a-card.bg, .a-cat{
        display: none;
    }

    .a-right{
        padding: 20px;
    }
    
    .a-title{
        font-size: 8vw;
        text-align: center;
    }

    .a-sub{
        font-size: 3.5vw;
    }

    .a-desc{
        font-size: 2.5vw;
    }


}