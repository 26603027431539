.pl{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pl-texts{
    width: 100%;
}

.pl-title{
    font-size: 6vw;
    font-weight: 600;
}

.pl-bold-text{
    font-size: 1.5vw;
    font-weight: 300;
}

.pl-bolder-text{
    font-size: 1.5vw;
    font-weight: 00;
}

.pl-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 550px) {
    .pl{
        margin-top: 10vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    
    .pl-texts{
        width: 100%;
    }
    
    .pl-title{
        font-size: 10vw;
        font-weight: 600;
    }
    
    .pl-bold-text{
        font-size: 5vw;
        font-weight: 300;
    }
    
    .pl-bolder-text{
        font-size: 5vw;
        font-weight: 00;
    }
    
    .pl-list{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}