.timeline {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.timeline-title {
  font-size: 6vw;
  font-weight: 600;
  text-align: center;
}

.vertical-timeline-element-title {
  font-weight: 600;
  font-size: 1.5vw;
  margin-bottom: 10px;
}

.vertical-timeline-element-content {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: 90%;
}

.vertical-timeline-element-subtitle {
  font-size: 1.2vw;
  color: #555;
  font-weight: 500;
  margin-bottom: 10px;
}

.vertical-timeline {
  max-width: 100vw;
}

.vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
  font-size: 1.1vw;
  font-weight: 300;
}

.vertical-timeline-element-content li,
.vertical-timeline-element-content p {
  font-size: 1vw;
  font-weight: 300;
  margin: 0px;
}

.vertical-timeline-element-content ul {
  padding-left: 15px;
}

.timeline-icon {
  background: #228b22;
  box-shadow: 0 0 0 4px #228b22;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-svg-icon {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media screen and (max-width: 550px) {
  .timeline {
    margin-top: 10vw;
  }

  .timeline-title {
    font-size: 10vw;
    font-weight: 600;
  }

  .vertical-timeline-element-title {
    font-size: 5vw;
  }

  .vertical-timeline-element-content {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 75vw;
    padding-right: 5vw;
  }

  .vertical-timeline-element-subtitle {
    font-size: 4vw;
  }

  .vertical-timeline-element-content li,
  .vertical-timeline-element-content p {
    font-size: 3vw;
  }

  .vertical-timeline-element-content ul {
    padding-left: 15px;
  }

  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    font-size: 2vw;
    font-weight: 300;
  }
}
